<template>
    <!-- Terms Modal -->
    <div class="modal fade modalbox" id="termsModal" tabindex="-1" role="dialog">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title"></h5>
                    <a href="javascript:void(0);" data-dismiss="modal">Close</a>
                </div>
                <div class="modal-body p-0">
                    <iframe
                        :src="`https://docs.google.com/gview?url=${$globalFunc.getHost}${termsConditions}&embedded=true`"
                        type="application/pdf"
                        width="100%"
                        height="700px"
                        frameborder="0"
                    />
                </div>
<!--                <div class="modal-body">-->
<!--                    <h3>RelaiEarn Terms & conditions</h3>-->
<!--                    <h5>Effective date: 1 January 2018</h5>-->
<!--                    <hr>-->

<!--                    <h4>Welcome to RelaiEarn</h4>-->
<!--                    <p>-->
<!--                        Before using our Services, please read the Terms of Service (the “Terms” or the “Agreement”)-->
<!--                        carefully, along with any other policies or notices on RelaiEarn’s website, and our-->
<!--                        [Privacy Policy](/privacy-policy). Together, these materials contain terms, rules, and-->
<!--                        guidelines related to your use of RelaiEarn. By using our Services, you are agreeing to-->
<!--                        these terms. Please read them carefully.-->
<!--                    </p>-->
<!--                    <p>-->
<!--                        Our Services are very diverse, so sometimes additional terms or product requirements-->
<!--                        (including age requirements) may apply. Additional terms will be available with the relevant-->
<!--                        Services, and those additional terms become part of your agreement with us if you use those Services.-->
<!--                    </p>-->

<!--                    <h4>1. Using our services</h4>-->
<!--                    <p>-->
<!--                        You must follow any policies made available to you within the Services.-->
<!--                    </p>-->
<!--                    <p>-->
<!--                        Don’t misuse our Services. For example, don’t interfere with our Services or try to access-->
<!--                        them using a method other than the interface and the instructions that we provide. You may use-->
<!--                        our Services only as permitted by law, including applicable export and re-export control laws-->
<!--                        and regulations. We may suspend or stop providing our Services to you if you do not comply with-->
<!--                        our terms or policies or if we are investigating suspected misconduct.-->
<!--                    </p>-->
<!--                    <p>-->
<!--                        Using our Services does not give you ownership of any intellectual property rights in our-->
<!--                        Services or the content you access. You may not use content from our Services unless you obtain-->
<!--                        permission from its owner or are otherwise permitted by law. These terms do not grant you the-->
<!--                        right to use any branding or logos used in our Services. Don’t remove, obscure, or alter any-->
<!--                        legal notices displayed in or along with our Services.-->
<!--                    </p>-->

<!--                    <h4>A. Personal Data that we collect about you.</h4>-->
<!--                    <p>-->
<!--                        Personal Data is any information that relates to an identified or identifiable individual. The-->
<!--                        Personal Data that you provide directly to us through our Sites will be apparent from the-->
<!--                        context in which you provide the data. In particular:-->
<!--                    </p>-->
<!--                    <ul>-->
<!--                        <li>-->
<!--                            When you create an RelaiEarn we collect your email address, and wallet log-in credentials.-->
<!--                        </li>-->
<!--                        <li>-->
<!--                            When you fill-in our online form to contact our support team, we collect your full name, work email, country, and anything else you tell us.-->
<!--                        </li>-->
<!--                    </ul>-->
<!--                    <p>-->
<!--                        When you respond to RelaiEarn emails or surveys we collect your email address, name and any other-->
<!--                        information you choose to include in the body of your email or responses. If you contact us by-->
<!--                        phone, we will collect the phone number you use to call RelaiEarn. If you contact us by phone as an-->
<!--                        RelaiEarn User, we may collect additional information in order to verify your identity.-->
<!--                    </p>-->

<!--                    <h4>B. Information that we collect automatically on our Sites.</h4>-->
<!--                    <p>-->
<!--                        We also may collect information about your online activities on websites and connected devices-->
<!--                        over time and across third-party websites, devices, apps and other online features and services.-->
<!--                        We use Google Analytics on our Sites to help us analyze Your use of our Sites and diagnose technical issues.-->
<!--                    </p>-->
<!--                    <p>-->
<!--                        To learn more about the cookies that may be served through our Sites and how You can control-->
<!--                        our use of cookies and third-party analytics, please see our Cookie Policy.-->
<!--                    </p>-->

<!--                    <h4>2. Privacy and copyright protection</h4>-->
<!--                    <p>-->
<!--                        RelaiEarn’s privacy policies explain how we treat your personal data and protect your privacy when-->
<!--                        you use our Services. By using our Services, you agree that RelaiEarn can use such data in accordance-->
<!--                        with our privacy policies.-->
<!--                    </p>-->
<!--                    <p>-->
<!--                        We respond to notices of alleged copyright infringement and terminate wallets of repeat-->
<!--                        infringers according to the process set out in the Czech Republic Digital Millennium Copyright Act.-->
<!--                    </p>-->
<!--                    <p>-->
<!--                        We provide information to help copyright holders manage their intellectual property online.-->
<!--                        If you think somebody is violating your copyrights and want to notify us, you can find information-->
<!--                        about submitting notices and RelaiEarn’s policy about responding to notices in our Contact Center.-->
<!--                    </p>-->

<!--                    <h4>3. Your assets in our services</h4>-->
<!--                    <p>-->
<!--                        Some of our Services allow you to upload, submit, store, send or receive assets. You retain-->
<!--                        ownership of any intellectual property rights that you hold in that asset. In short, what belongs to you stays yours.-->
<!--                    </p>-->
<!--                    <p>-->
<!--                        When you upload, submit, store, send or receive assets to or through our Services, you give-->
<!--                        RelaiEarn (and those we work with) a worldwide license to use, host, store, reproduce, modify, create-->
<!--                        derivative works (such as those resulting from translations, adaptations or other changes we make so-->
<!--                        that your assets works better with our Services). The rights you grant in this license are for the-->
<!--                        limited purpose of operating, promoting, and improving our Services, and to develop new ones.-->
<!--                        This license continues even if you stop using our Services.-->
<!--                    </p>-->
<!--                </div>-->
            </div>
        </div>
    </div>
    <!-- * Terms Modal -->
</template>

<script>
import termsConditions from "../../../assets/parent/Terms_conditions.pdf"
export default {
    name: "terms_conditions",
    data(){
        return {
            termsConditions
        }
    }
}
</script>

<style scoped>
h5, h3,h4,h6{
    color: #12326B
}
h4{
    margin-top: 20px;
}
h6{
    margin-top: 12px;
}
p{
    line-height: 30px;
}
ul{
    padding-left: 2rem;
}
ul li{
    list-style-type: disc!important;
}
ul li::marker{
    color: #12326B;
}
</style>