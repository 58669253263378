module.exports = function(){
    $(function(){
        // $('#loader').fadeIn();
        $('head').append(`
            <link rel="stylesheet" href="/assets/parent/css/style.css">
        `);

        setTimeout(()=>{
            $('body').append(`
            <script src="/assets/parent/vendors/jquery/jquery.min.js"></script>
            <script src="/assets/parent/vendors/bootstrap/popper.min.js"></script>
            <script src="/assets/parent/vendors/bootstrap/bootstrap.min.js"></script>
            <script src="/assets/parent/js/plugins.min.js"></script>
            <script src="/assets/parent/js/active.js"></script>
        `);
            // const body = document.getElementsByTagName('body')[0];
            // body.addEventListener('load', ()=>{
            //     $('#loader').fadeOut();
            // })
        }, 1000)


    })
}