<template>
    <!-- ***** Header Start ***** -->
    <header class="navbar navbar-sticky navbar-expand-lg navbar-dark">
        <div class="container position-relative">
            <a class="navbar-brand" href="javascript:void(0)">
                <img class="navbar-brand-regular" src="../assets/img/metric_logo_w.png" alt="brand-logo">
                <img class="navbar-brand-sticky" src="../assets/img/metric_logo_bw.png" alt="sticky brand-logo">
            </a>
            <button class="navbar-toggler d-lg-none" type="button" data-toggle="navbarToggler" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>

            <div class="navbar-inner">
                <!--  Mobile Menu Toggler -->
                <button class="navbar-toggler d-lg-none" type="button" data-toggle="navbarToggler" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <nav>
                    <ul class="navbar-nav" id="navbar-nav">
                        <li class="nav-item">
                            <a class="nav-link" href="/" target="_self">
                                Home
                            </a>
                        </li>
                        <li class="nav-item">
                            <a href="/desktop" target="_self" class="nav-link">Wallet</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="/about" target="_self">
                                About
                            </a>
                        </li>
<!--                        <li class="nav-item">-->
<!--                            <a class="nav-link scroll" href="#" data-toggle="modal" data-target="#privacyModal">-->
<!--                                Privacy Policy-->
<!--                            </a>-->
<!--                        </li>-->
<!--                        <li class="nav-item dropdown">-->
<!--                            <a class="nav-link dropdown-toggle" href="javascript:void(0)"  data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">-->
<!--                                Pages-->
<!--                            </a>-->
<!--                            <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">-->
<!--                                <li class="dropdown-submenu"><a class="dropdown-item dropdown-toggle" href="javascript:void(0)">Inner Pages<span class="badge badge-pill badge-warning ml-2">New</span></a>-->
<!--                                    <ul class="dropdown-menu">-->
<!--                                        <li>-->
<!--                                            <a class="dropdown-item" href="javascript:void(0)">Pricing</a>-->
<!--                                        </li>-->
<!--                                        <li>-->
<!--                                            <a class="dropdown-item" href="javascript:void(0)">Download</a>-->
<!--                                        </li>-->
<!--                                        <li>-->
<!--                                            <a class="dropdown-item" href="javascript:void(0)">Newsletter</a>-->
<!--                                        </li>-->
<!--                                        <li>-->
<!--                                            <a class="dropdown-item" href="javascript:void(0)">Thank you</a>-->
<!--                                        </li>-->
<!--                                        <li>-->
<!--                                            <a class="dropdown-item" href="javascript:void(0)">Coming Soon</a>-->
<!--                                        </li>-->
<!--                                        <li>-->
<!--                                            <a class="dropdown-item" href="javascript:void(0)">404</a>-->
<!--                                        </li>-->
<!--                                    </ul>-->
<!--                                </li>-->
<!--                                <li class="dropdown-submenu"><a class="dropdown-item dropdown-toggle" href="javascript:void(0)">Blog Pages</a>-->
<!--                                    <ul class="dropdown-menu">-->
<!--                                        <li>-->
<!--                                            <a class="dropdown-item" href="javascript:void(0)">Blog- 2 Column</a>-->
<!--                                        </li>-->
<!--                                        <li>-->
<!--                                            <a class="dropdown-item" href="javascript:void(0)">Blog- 3 Column</a>-->
<!--                                        </li>-->
<!--                                        <li>-->
<!--                                            <a class="dropdown-item" href="javascript:void(0)">Blog- Left Sidebar</a>-->
<!--                                        </li>-->
<!--                                        <li>-->
<!--                                            <a class="dropdown-item" href="javascript:void(0)">Blog- Right Sidebar</a>-->
<!--                                        </li>-->
<!--                                    </ul>-->
<!--                                </li>-->
<!--                                <li class="dropdown-submenu"><a class="dropdown-item dropdown-toggle" href="javascript:void(0)">Blog Details</a>-->
<!--                                    <ul class="dropdown-menu">-->
<!--                                        <li>-->
<!--                                            <a class="dropdown-item" href="javascript:void(0)">Blog Details- Left Sidebar</a>-->
<!--                                        </li>-->
<!--                                        <li>-->
<!--                                            <a class="dropdown-item" href="javascript:void(0)">Blog Details- Right Sidebar</a>-->
<!--                                        </li>-->
<!--                                    </ul>-->
<!--                                </li>-->
<!--                                <li class="dropdown-submenu"><a class="dropdown-item dropdown-toggle" href="javascript:void(0)">Accounts<span class="badge badge-pill badge-warning ml-2">New</span></a>-->
<!--                                    <ul class="dropdown-menu">-->
<!--                                        <li>-->
<!--                                            <a class="dropdown-item" href="javascript:void(0)">Login</a>-->
<!--                                        </li>-->
<!--                                        <li>-->
<!--                                            <a class="dropdown-item" href="javascript:void(0)">Signup</a>-->
<!--                                        </li>-->
<!--                                        <li>-->
<!--                                            <a class="dropdown-item" href="javascript:void(0)">Reset Password</a>-->
<!--                                        </li>-->
<!--                                    </ul>-->
<!--                                </li>-->
<!--                                <li>-->
<!--                                    <a class="dropdown-item" href="javascript:void(0)">Reviews</a>-->
<!--                                </li>-->
<!--                                <li>-->
<!--                                    <a class="dropdown-item" href="javascript:void(0)">FAQ</a>-->
<!--                                </li>-->
<!--                                <li>-->
<!--                                    <a class="dropdown-item" href="javascript:void(0)">Contact</a>-->
<!--                                </li>-->
<!--                                <li>-->
<!--                                    <a class="dropdown-item disabled" href="#">More Coming Soon</a>-->
<!--                                </li>-->
<!--                            </ul>-->
<!--                        </li>-->
                    </ul>
                </nav>
            </div>
        </div>
    </header>
    <!-- ***** Header End ***** -->
</template>

<script>
export default {
    name: 'topNav',
};
</script>

<style scoped>
.navbar-brand img{
    width: 160px;
    height: 50px;
    /*color: #12326B*/
}
</style>