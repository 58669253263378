<template>
    <!--====== Footer Area Start ======-->
    <footer class="footer-area footer-fixed">
        <!-- Footer Top -->
        <div class="footer-top ptb_100">
            <div class="container">
                <div class="row">
                    <div class="col-12 col-sm-6 col-lg-4">
                        <!-- Footer Items -->
                        <div class="footer-items">
                            <!-- Logo -->
                            <a class="navbar-brand" href="#">
                                <img class="logo" src="../assets/img/metric_logo_bw.png" alt="" style="width:200px;height:60px">
                            </a>
                            <p class="mt-2 mb-3">
                                EarnRelai’s mission is to help crypto currency investors grow their investments on their own terms                            </p>
                            <!-- Social Icons -->
<!--                            <div class="social-icons d-flex">-->
<!--                                <a class="facebook" href="#">-->
<!--                                    <i class="fab fa-facebook-f"></i>-->
<!--                                    <i class="fab fa-facebook-f"></i>-->
<!--                                </a>-->
<!--                                <a class="twitter" href="#">-->
<!--                                    <i class="fab fa-twitter"></i>-->
<!--                                    <i class="fab fa-twitter"></i>-->
<!--                                </a>-->
<!--                                <a class="google-plus" href="#">-->
<!--                                    <i class="fab fa-google-plus-g"></i>-->
<!--                                    <i class="fab fa-google-plus-g"></i>-->
<!--                                </a>-->
<!--                                <a class="vine" href="#">-->
<!--                                    <i class="fab fa-vine"></i>-->
<!--                                    <i class="fab fa-vine"></i>-->
<!--                                </a>-->
<!--                            </div>-->
                        </div>
                    </div>
                    <div class="col-12 col-sm-6 col-lg-4">
                        <!-- Footer Items -->
                        <div class="footer-items">
                            <!-- Footer Title -->
                            <h3 class="footer-title mb-2">Useful Links</h3>
                            <ul>
                                <li class="py-2"><a href="/desktop" target="_self">Wallet</a></li>
                                <li class="py-2">
                                    <a href="#" data-toggle="modal" data-target="#termsModal">
                                        Our Terms
                                    </a>
                                </li>
                                <li class="py-2">
                                    <a href="#" data-toggle="modal" data-target="#privacyModal">
                                        Privacy Policy
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-12 col-sm-6 col-lg-4">
                        <!-- Footer Items -->
                        <div class="footer-items">
                            <!-- Footer Title -->
                            <h3 class="footer-title mb-2">Wallet</h3>
                            <!-- Store Buttons -->
                            <div class="button-group store-buttons store-black d-flex flex-wrap">
                                <a href="/desktop" target="_self" class="btn btn-bordered mt-4">Go to Wallet</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Footer Bottom -->
        <div class="footer-bottom">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <!-- Copyright Area -->
                        <div class="copyright-area d-flex flex-wrap justify-content-center justify-content-sm-between text-center py-4">
                            <!-- Copyright Left -->
                            <div class="copyright-left">&copy; Copyrights {{(()=>new Date().getFullYear())()}} Relai All rights reserved.</div>
                            <!-- Copyright Right -->
                            <div class="copyright-right">Tested <i class="fas fa-check"></i> and Trusted</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
    <!--====== Footer Area End ======-->
</template>

<script>
export default {
    name: 'footNav',
};
</script>

<style scoped>

</style>