<template>
    <div>
        <!--====== Scroll To Top Area Start ======-->
        <div id="scrollUp" title="Scroll To Top">
            <i class="fas fa-arrow-up"></i>
        </div>
        <!--====== Scroll To Top Area End ======-->

        <div class="main">
            <top-nav/>
            <router-view/>
            <foot-nav/>
            <terms-conditions/>
            <privacy-policy/>
        </div>
    </div>
</template>

<script>
import topNav from '../../components/topNav';
import footNav from '../../components/footNav';
import termsConditions from '../../components/modals/home/terms_conditions';
import privacyPolicy from '../../components/modals/home/privacy_policy';

export default {
    name: 'Home',
    components: {
        topNav,
        footNav,
        termsConditions,
        privacyPolicy
    },
    mounted() {
        const home_imports = require("../../assets/parent/js/home_imports");
        home_imports();
    }
};
</script>

<style>
.notification-box{
    display: none;
}
</style>